import Layout from "./layout";
import LoadingScreen from "./loading-screen";
import AppContext from "../app-context";
import { API } from "@aws-amplify/api";
import React, { useContext, useEffect, useState } from "react";

export default function (props) {
  const { user, setUser } = useContext(AppContext);

  useEffect(async () => {
    const response = await API.get("trant", `/user`, {});
    setUser({
      ...user,
      ...JSON.parse(response.body),
    });
  });

  if (!user || !user.paidScenarios) {
    return <LoadingScreen />;
  }

  return (
    <Layout>
      <p style={{ fontSize: "18px", padding: "var(--scale400)" }}>Your payment was successful. The paid content is now unlocked. </p>
    </Layout>
  );
}
