import { loadStripe } from "@stripe/stripe-js";
import { IonButton } from "@ionic/react";
import styled from "styled-components";

const CenteredButton = styled(IonButton)`
  --background: var(--ion-color-medium);
  --color: var(--ion-color-dark);
  display: block;
  width: 85%;
  padding: 0 12px;
  margin: 12px auto;
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBKEY);

const handleClickCheckout = async (userId) => {
  const stripe = await stripePromise;

  // NOTE: THE DOMAIN NEEDS TO BE ADDED IN https://dashboard.stripe.com/settings/checkout
  stripe
    .redirectToCheckout({
      lineItems: [{ price: process.env.REACT_APP_STRIPE_PRODUCT_PRICE, quantity: 1 }], //use live version for production
      mode: "payment",
      successUrl: process.env.REACT_APP_APP_URL + "/success",
      cancelUrl: process.env.REACT_APP_APP_URL + "/failure",
      clientReferenceId: userId,
    })
    .then(function (result) {
      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer.
        console.log(result.error);
      }
    });
};

export default function ({ userId }) {
  return <CenteredButton onClick={() => handleClickCheckout(userId)}>Buy Now</CenteredButton>;
}
