import React from "react";
import useAuth from "../api/use-auth";
import Layout from "./layout";

export default function PrivateRoute({ component: Component, ...rest }) {
  const [status] = useAuth();

  let renderComponent = null;
  if (status.isAuthReady) {
    if (status.isAuthenticated) {
      renderComponent = <Component {...rest} />;
    } else {
      // navigate(`/`)
      renderComponent = (
        <Layout>
          <div style={{ margin: "var(--scale500)" }}>You are not logged in. Please Log in and try again.</div>{" "}
        </Layout>
      );
    }
  } else {
    //null till ready
  }

  return renderComponent;
}
