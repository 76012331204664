import AppContext from "../app-context";
import { lockClosed } from "ionicons/icons";
import { IonList, IonItem, IonLabel, IonIcon } from "@ionic/react";
import React, { useContext } from "react";

/**
 * Overview component which can inform the caller of the selected ID.
 *
 * @param  setId Function to be called to provided the caller with the ID of the section selected by the user.
 * @returns Overview component.
 */
export default function Overview({ setId }) {
  const { user } = useContext(AppContext);

  // IDs of paid sections and the label we want to show.
  const idLabelPairs = [
    { id: "Demo1", label: "General" },
    { id: "Rev1", label: "Reversing" },
    { id: "Close1", label: "Closing" },
    { id: "Open1", label: "Openers" },
    { id: "MEDDIC1", label: "MEDDIC" },
  ];

  return (
    <IonList>
      {idLabelPairs.map((pair) => {
        const isUnlocked = pair.id === idLabelPairs[0].id || (user && user.paidScenarios);
        return (
          <IonItem
            key={pair.id}
            button={isUnlocked}
            onClick={() => {
              if (isUnlocked) {
                setId(pair.id);
              }
            }}
          >
            <IonLabel>{pair.label} </IonLabel>
            {!isUnlocked && <IonIcon icon={lockClosed}></IonIcon>}
          </IonItem>
        );
      })}
    </IonList>
  );
}
