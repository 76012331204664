import Layout from "../components/layout";
import AppContext from "../app-context";
import { ScenariosList } from "../data/scenarios-types";
import ScenarioCardsPlayer from "../components/scenario-cards-player";
import Overview from "../components/overview";
import demoScenarios from "../data/demo-scenarios.json";
import { useState, useContext, useEffect } from "react";

export default function (props) {
  const { user }: any = useContext(AppContext);
  const [startScenarioId, setStartScenarioId] = useState();

  useEffect(() => {
    // reset the state if the page is visited again
    setStartScenarioId(undefined);
  }, [props.location.key]);

  let scenarios: ScenariosList = demoScenarios;

  if (user && user.paidScenarios) {
    scenarios = [...demoScenarios, ...user.paidScenarios];
  }

  return (
    <Layout>
      {startScenarioId === undefined ? (
        <>
          <div style={{ height: "var(--scale400)" }} />
          <Overview setId={(id) => setStartScenarioId(id)} />
        </>
      ) : (
        <ScenarioCardsPlayer scenariosList={scenarios} startScenarioId={startScenarioId} />
      )}
    </Layout>
  );
}
