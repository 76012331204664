import Layout from "./layout";
import AppContext from "../app-context";

import { IonButton } from "@ionic/react";
import styled from "styled-components";
import React, { useContext } from "react";

const CenteredButton = styled(IonButton)`
  --background: var(--ion-color-medium);
  --color: var(--ion-color-dark);
  display: block;
  width: 85%;
  padding: 0 12px;
  margin: var(--scale600) auto;
`;

export default function (props) {
  const { isAuthenticated } = useContext(AppContext);

  const CardsButtonText = isAuthenticated ? "Play" : "Demo";

  return (
    <Layout>
      <div>
        <h2>Does cold calling give you a cold sweat?</h2>
        <p>Well, you may want to start getting good at it, as the phone is the easiest and fastest means of setting high quality meetings.</p>
        <p>
          As salespeople, entrepreneurs and business leaders, you might have heard the same objections previously and have a basic idea of how you
          might answer them. Nonetheless, in the heat of the moment it's hard to respond quickly with a competent answer every time, unless you have
          prepared the answer out loud.
        </p>
        <p>
          Have you ever prepared before a meeting or interview by reading answers online and practicing in your head? Then when you tried to use that
          answer in real-life there was a disconnect between how something sounded in your head to how it sounded leaving your mouth.
        </p>
        <p>
          It's for that reason that to get the most value from these digital flashcards we recommend speaking the words aloud before moving on to the
          next objection.
        </p>
        <p>Practice with digital flashcards so you don't have to practice on your prospects and nail every objection. Then you become unstoppable.</p>
        <h2>How it works</h2>
        <p>
          Simply answer aloud as you would before looking at the answer. Then study the model answer, practice saying it aloud if you can. Keep
          playing until you can say the model response without checking the card.
        </p>
        Feel free to shoot us an email if you have any questions: hello@trant.io
      </div>
      <CenteredButton
        onClick={() => {
          props.history.push("/cards");
        }}
      >
        {CardsButtonText}
      </CenteredButton>
    </Layout>
  );
}
