import { LeftArrowButton, RightArrowButton } from "./arrow-buttons";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Label = styled.div`
  min-width: 240px;
`;

export default function DirectionHorizontalInput(props) {
  return (
    <Container style={props.style}>
      <LeftArrowButton isDisabled={props.isDisabledLeft} onClick={props.onPrev} />
      <Label>{props.label}</Label>
      <RightArrowButton isDisabled={props.isDisabledRight} onClick={props.onNext} />
    </Container>
  );
}
