import { IonButton } from "@ionic/react";
import styled from "styled-components";

const CenteredButton = styled(IonButton)`
  --background: var(--ion-color-medium);
  --color: var(--ion-color-dark);
  display: block;
  width: 85%;
  padding: 0 12px;
  margin: 12px auto;
`;

const FinalScore = styled.div`
  background: var(--ion-color-light-shade);
  font-size: 42px;
  text-align: center;
  line-height: 86px;
  color: var(--ion-color-dark);
  width: 112px;
  margin: 36px auto;
  border-radius: 30%;
`;

export default function (props) {
  return (
    <div>
      <FinalScore>{props.metrics.finalScore}</FinalScore>
      <CenteredButton onClick={() => props.onPrev()}>Back to cards</CenteredButton>
    </div>
  );
}
