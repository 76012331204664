import { pageContainerName } from "../app-constants";
import useAuth from "../api/use-auth";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonButton } from "@ionic/react";

function Header() {
  const [status, login, logout] = useAuth();

  const userAuthButton = status.isAuthenticated ? <IonButton onClick={logout}>Logout</IonButton> : <IonButton onClick={login}>Login</IonButton>;

  return (
    <IonHeader>
      <IonToolbar style={{ maxWidth: "var(--scale1400)", margin: "auto" }}>
        <IonTitle>Objection Handling Flashcards</IonTitle>
        <IonButtons slot="primary">{userAuthButton}</IonButtons>
      </IonToolbar>
    </IonHeader>
  );
}

export default function (props) {
  return (
      <IonPage id={pageContainerName}>
        <Header />
        <IonContent
          style={{ maxWidth: "var(--scale1400)", alignSelf: "center", "--padding-start": "var(--scale100)", "--padding-end": "var(--scale100)" }}
        >
          {props.children}
        </IonContent>
      </IonPage>
  );
}
