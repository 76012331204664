import Layout from "../components/layout";

export default function (props) {
  return (
    <Layout>
      <section>
        <h1 style={{ marginTop: "var(--scale600)" }}>Terms of Service</h1>
        <p>Last updated 16 June 2022</p>
        <p>
          Welcome to trant.io! These Terms of Service (the “Terms”) and our 
          <a href="/privacy">Privacy Policy</a> govern your access to and use of the trant.io services and the website, including any browser
          extensions, mobile applications and other downloadable apps we provide (collectively, “trant.io”), so please read them carefully.
        </p>
        <p>
          By using trant.io, you are agreeing to these Terms and the use of your Personal Information as described in our 
          <a href="/privacy">Privacy Policy</a>. If you do not agree to these Terms, do not use trant.io.
        </p>
        <p>
          We may periodically revise the Terms. If a revision becomes available we will notify you, for example via email. The current version of our
          Terms will always be posted on our Terms page, so please check back regularly. By continuing to use trant.io after revisions become
          effective, you are agreeing to the revised Terms. If you do not agree to the revised Terms, please stop using trant.io.
        </p>

        <h2>Your trant.io Account</h2>
        <p>
          To use trant.io, you'll need to create an account, either via trant.io or through a third-party service such as Google. In the latter case,
          personal information you provided to that third party, such as your name, email address, and other information that your privacy settings on
          that service allow us to access, will be used to create your trant.io account
        </p>
        <p>
          You are responsible for safeguarding your trant.io login credentials. You are responsible for activity on your account, whether or not you
          authorized that activity. You should immediately notify us of any unauthorized use of your account.
        </p>

        <h2>Your Content</h2>
        <p>
          By using trant.io, you provide us with information (“your content”) that specifies the financial simulation you would like us to perform for
          you. You retain full ownership of your content - what belongs to you stays yours. You can remove your content by deleting it.
        </p>

        <h2>Your Use of trant.io</h2>
        <p>
          trant.io is owned by trant.io Analytics Inc. and is protected by United States and international copyright, trademark, patent, trade secret
          and other intellectual property or proprietary rights laws.
        </p>
        <p>
          You may only use trant.io as permitted by law, including all applicable federal, state, local or international laws and regulations. Do not,
          for example:
        </p>
        <ul>
          <li>
            Use any engine, software, tool, agent, device, mechanism or the like to access, search, or download intellectual property from trant.io,
            or use trant.io in any way other than through our publicly supported interfaces;
          </li>
          <li>
            Access, tamper with, or use non-public areas of trant.io, trant.io’s computer systems, or the technical delivery systems of trant.io’s
            providers;
          </li>
          <li>Probe, scan, or test the vulnerability of any trant.io system or network or breach any security or authentication measures;</li>
          <li>Decipher, decompile, disassemble or reverse engineer any of the software used to provide trant.io;</li>
          <li>Plant malware or use trant.io to distribute malware;</li>
          <li>Violate the privacy of others;</li>
          <li>Violate any applicable law or regulation;</li>
          <li>
            Impersonate or misrepresent your affiliation with any person or entity; or post or transmit anything that is fraudulent or misleading;
          </li>
          <li>Send unsolicited communications, promotions, advertisements or spam or otherwise infringe on others' rights;</li>
          <li>
            Interfere with the access of any user, host or network, including introducing any virus to, overloading, flooding, spamming, or
            mail-bombing trant.io, or introducing any other material or content which is malicious or technologically harmful;
          </li>
          <li>
            Attack trant.io via a denial-of-service attack or a distributed denial-of-service attack; or otherwise attempt to interfere with the
            proper working of trant.io;
          </li>
          <li>Attempt any of the above, or encourage or enable any other individual to do any of the above.</li>
        </ul>
        <p>
          We have the right to investigate violations of these Terms and may also consult and cooperate with law enforcement authorities to prosecute
          users who violate the law.
        </p>

        <h2>Confidentiality</h2>
        <p>
          <i>Confidential Information.</i> From time to time, either party (the "Disclosing Party") may disclose or make available to the other party
          (the "Receiving Party") non-public, proprietary, and confidential information of the Disclosing Party (“Confidential Information”).
          Confidential Information includes any information that reasonably should be understood to be confidential given the nature of the
          information and the circumstances of disclosure, including non-public business, product, technology and marketing information ("Confidential
          Information"). Confidential Information does not include any information that: (a) is or becomes generally available to the public other
          than as a result of the Receiving Party's breach of this confidentiality section; (b) is or becomes available to the Receiving Party on a
          non-confidential basis from a third party source, provided that such third party is not and was not prohibited from disclosing such
          Confidential Information; (c) was in the Receiving Party's possession prior to the Disclosing Party's disclosure hereunder; or (d) was or is
          independently developed by the Receiving Party without using any of the Disclosing Party Confidential Information.
        </p>
        <p>
          <i>Protection and Use of Confidential Information.</i> The Receiving Party shall: (a) protect and safeguard the confidentiality of the
          Disclosing Party's Confidential Information with at least the same degree of care as the Receiving Party would protect its own Confidential
          Information, but in no event with less than a commercially reasonable degree of care; (b) not use the Disclosing Party's Confidential
          Information, or permit it to be accessed or used, for any purpose other than to exercise its rights or perform its obligations under these
          Terms; and (c) not disclose any such Confidential Information to any person or entity, except to the Receiving Party's service providers or
          financial/legal advisors who need to know the Confidential Information and are bound to confidentiality obligations at least as restrictive
          as those in these Terms.
        </p>
        <p>
          <i>Compelled Access or Disclosure.</i> If the Receiving Party is required by applicable law or legal process to disclose any Confidential
          Information, it shall, prior to making such disclosure, use commercially reasonable efforts to notify the Disclosing Party of such
          requirements to afford the Disclosing Party the opportunity to seek, at the Disclosing Party's sole cost and expense, a protective order or
          other remedy.
        </p>

        <h2>Termination</h2>
        <p>
          We may terminate or modify your access to and use of trant.io, at our sole discretion, at any time and without notice to you, for example,
          if you are not complying with these Terms, or if you use trant.io in any way that would cause us legal liability or disrupt others’ use of
          trant.io.
        </p>
        <p>Likewise, you may cancel your account at any time, although we will be sorry to see you go.</p>
        <p>
          If we suspend or terminate your use of trant.io, we will try to let you know in advance and help you retrieve data, though there may be
          cases (for example, flagrantly violating these Terms) where we may suspend immediately.
        </p>

        <h2>Publicity</h2>
        <p>
          Unless otherwise specified, trant.io may use Customer’s name, logo and marks (including marks on Customer Properties) to identify Customer
          as an trant.io customer on trant.io's website and other marketing materials.
        </p>

        <h2>Warranty Disclaimers</h2>
        <p>
          trant.io IS PROVIDED “AS IS,” AT YOUR OWN RISK, WITHOUT EXPRESS OR IMPLIED WARRANTY OR CONDITION OF ANY KIND. WE ALSO DISCLAIM ANY
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. trant.io Analytics Inc. will have no responsibility for
          any harm to your computer system, loss or corruption of data, or other harm that results from your access to or use of trant.io. Some states
          do not allow the types of disclaimers in this paragraph, so they may not apply to you.
        </p>

        <h2>Indemnity</h2>
        <p>
          You will hold harmless and indemnify trant.io Analytics Inc. and its affiliates, officers, directors, employees, contractors, agents,
          licensors, and suppliers from and against any claim, suit or action arising from or related to the use of trant.io or violation of these
          Terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
          (A) TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL trant.io ANALYTICS INC., ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS
          OR LICENSORS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF USE, PROFIT,
          DATA, GOOD WILL, SERVICE INTERRUPTIONS, COMPUTER DAMAGE OR SYSTEM FAILURE), REGARDLESS OF LEGAL THEORY, WHETHER OR NOT trant.io ANALYTICS
          INC. HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE; (B) AGGREGATE LIABILITY FOR
          ALL CLAIMS RELATING TO trant.io SHALL BE NO MORE THAN THE GREATER OF $5 OR THE AMOUNTS PAID BY YOU TO trant.io ANALYTICS INC. FOR THE PAST
          THREE MONTHS OF THE SERVICES IN QUESTION. Some states do not allow the types of limitations in this paragraph. If you are in one of these
          jurisdictions, these limitations may not apply to you.
        </p>

        <h2>Terms of Paid Service</h2>
        <p>If you purchase a service via the trant.io website, the following terms of subscription will apply to you:</p>
        <p>
          Billing and Payment. If you purchase a service via credit card, debit card or other payment card (collectively, “Credit Card”), you hereby
          authorize trant.io (or its designee) to automatically charge your Credit Card in accordance with the applicable order. You acknowledge that
          certain Credit Cards may charge you foreign transaction fees or other charges. If your payment is not successfully settled for any reason,
          you remain responsible for any amounts not remitted to trant.io.
        </p>
        <p>
          Taxes and Fees. You are responsible for all sales, use, value added or other taxes of any kind, other than taxes based on trant.io’s net
          income. You are also responsible for any payment-related fees such as wire transfer or Credit Card processing fees.
        </p>
        <p>
          Expenses. You are responsible for all fees or expenses related to accessing or using the Services that are extrinsic to the Services. This
          includes, without limitation, your own internet service provider fees.
        </p>

        <h2>General Terms</h2>
        <p>
          By visiting trant.io's website, you agree that the laws of the state of Delaware, without regard to principles of conflict of laws, will
          govern these Terms and any dispute of any sort that might arise between you and trant.io Analytics Inc. These Terms constitute the entire
          and exclusive agreement between you and trant.io Analytics Inc., and supersede and replace any other agreements, terms and conditions. These
          Terms create no third party beneficiary rights. trant.io Analytics Inc.’s failure to enforce a provision is not a waiver of its right to do
          so later. If a provision is found unenforceable, the remaining provisions of the Agreement will remain in full effect and an enforceable
          term will be substituted reflecting our intent as closely as possible. You may not assign any of your rights in these Terms without our
          written consent, and any such attempt will be null and have no effect. trant.io Analytics Inc. may freely assign or transfer these terms
          without restriction. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms
          will be without prejudice to its other remedies under these Terms or otherwise.
        </p>

        <h2>Contact Information</h2>
        <p>
          If you have any questions about these Terms, please contact us at 
          <a href="mailto:legal@trant.io">legal@trant.io</a>.
        </p>
      </section>
    </Layout>
  );
}
