import React, { createContext, useState, useEffect } from "react";
import { Amplify } from "@aws-amplify/core";
import { ScenariosList } from "./data/scenarios-types";

const AppContext = createContext({});

export default AppContext;

export interface User {
  id: string;
  subscriptionExpireDate: number;
  paidScenarios: ScenariosList;
}

export interface Context {
  isApiReady: boolean; //TODO remove?
  isAuthReady: boolean; //TODO remove?
  isAuthenticated: boolean;
  user?: User;
  setIsAuthReady: any; //TODO remove?
  setIsAuthenticated: any; //TODO remove?
  setUser: any;
}

export const AppContextProvider = ({ children }) => {
  useEffect(() => {
    Amplify.configure({
      Auth: {
        mandatorySignIn: false,
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
        oauth: {
          domain: process.env.REACT_APP_OAUTH_DOMAIN,
          scope: ["email", "profile", "openid"],
          redirectSignIn: `${process.env.REACT_APP_APP_URL}/`,
          redirectSignOut: `${process.env.REACT_APP_APP_URL}/`,
          responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
      API: {
        endpoints: [
          {
            name: "trant",
            endpoint: process.env.REACT_APP_TRANT_API_URL,
            region: process.env.REACT_APP_AWS_REGION,
          },
        ],
      },
    });
    setContext((prevState) => {
      return {
        ...prevState,
        isApiReady: true,
      };
    });
  }, []); // [] so that the effect is applied only the first time

  const contextInit: Context = {
    isApiReady: false,
    isAuthReady: false,
    isAuthenticated: false,

    setIsAuthReady: (isAuthReady) => {
      setContext((prevState) => {
        return {
          ...prevState,
          isAuthReady,
        };
      });
    },
    setIsAuthenticated: (isAuthenticated) => {
      setContext((prevState) => {
        return {
          ...prevState,
          isAuthenticated,
        };
      });
    },
    setUser: (user: User) => {
      setContext((prevState) => {
        return {
          ...prevState,
          user,
        };
      });
    },
  };

  const [context, setContext]= useState(contextInit); // the setter needs to be used in the init

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
