import { ReactComponent as AngleArrow } from "../../data/angle-arrow.svg";
import styled from "styled-components";
import React, { useState, useEffect } from "react";

// Note: don't apply any shadow that shouldn't be rotated
const Container = styled.button`
  width: var(--scale600);
  height: var(--scale600);
  padding: 4px;
  border-radius: 3%;
  box-shadow: none;
  display: grid;
  align-items: center;
  justify-items: center;
  border: none;
  background-color: inherit;
  color: ${(props) => (props.isDisabled ? "var(--grey600)" : "var(--blue400)")};
  cursor: pointer;
  pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};

  :hover {
    animation: scaleKf 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }
  @keyframes scaleKf {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.25);
    }
  }
`;

export function RightArrowButton(props) {
  const [isLoading, setIsLoading] = useState(false);
  const innerPorion = 0.7;
  const angle = props.angle ? props.angle : 0;

  useEffect(() => {
    return () => setIsLoading(false);
  });

  return (
    <Container
      style={props.style}
      onClick={async (event) => {
        setIsLoading(true);
        await props.onClick(event);
        setIsLoading(false);
      }}
      innerPorion={innerPorion}
      backgroundColor={props.backgroundColor}
      isDisabled={isLoading || props.isDisabled}
      color={props.color}
    >
      <AngleArrow style={{ height: "100%", transform: `rotate(${angle})` }} />
    </Container>
  );
}

export function LeftArrowButton(props) {
  return <RightArrowButton {...props} angle="180deg" />;
}
