import Layout from "../components/layout";

export default function (props) {
  return (
    <Layout>
      <section>
        <h1 style={{ marginTop: "var(--scale600)" }}>Privacy Policy</h1>
        <p>Last updated 16 June 2022</p>
        <p>
          We – trant.io – prepared this Privacy Policy to help you understand how your personal information is collected, used and shared when you
          visit or use trant.io and/or any related services (together, "Sites" or “Services”). By accessing or using the Services, you agree to this
          Privacy Policy and our <a href="/terms">Terms of Service</a>.
        </p>

        <h2>What Information We Collect</h2>
        <p>
          What types of information we collect from you depends on how you use the Services, what Services you use, and what information you choose to
          provide or make available to us. We collect information from you when you:
        </p>
        <ul>
          <li>Create or register an account, and when you administer your account</li>
          <li>Input information or data into any of our Services</li>
          <li>Submit questions, requests, other communications to us via forms, email, or other communication media</li>
          <li>Contact us for customer support or technical support</li>
          <li>Visit any of our websites or download any of our apps</li>
          <li>Participate in any promotions, demonstrations, contests, surveys, or other marketing events</li>
        </ul>

        <h3>Customer Information</h3>
        <p>
          Information related to the creation of accounts or that otherwise identifies you as a customer or end user of our Services is what we call
          "Customer Information." Customer Information may include certain "personal information", such as your name, email address, gender, postal
          address and phone number. It also may include payment information, such as payment method, credit card information, and any contact
          information (such as name and postal address) associated with payment billing information.
        </p>
        <p>
          When you create an account with us, we may collect certain Customer Information directly from you or, if you create your account using a
          third-party service (such as Google or Facebook), we may collect Customer Information about you from the third-party service (such as your
          email or user ID associated with that third-party service). By choosing to create an account using a third-party service, you authorize us
          to collect Customer Information necessary to authenticate your account with the third-party service provider.
        </p>

        <h3>Information collected automatically through your use of the Services</h3>
        <p>
          We collect information about how you use the Services and your actions on the Services, including IP addresses, what software and hardware
          you use (such as browser types, operating systems, ISPs, platform type, device type, mobile device identifiers such as make and model,
          mobile carrier), pages or features of our Sites used and associated dates and time stamps, search terms, links you click, whether you open
          messages sent to your account, and other statistics related to your usage of the Services. We may also use tools, including third-party
          tools, to collect analytics data. Some of this information is collected through the use of "cookies" and other tracking technologies, such
          as web beacons, session replay scripts, and similar technologies ("tracking technologies"). We may also work with third party partners to
          employ tracking technologies.
        </p>
        <p>
          We, as well as third parties that provide content, advertising, or other functionality on the Services, may use cookies, pixel tags, local
          storage, and other technologies to automatically collect information through the Services. Our uses of these tracking technologies fall into
          the following general categories:
        </p>
        <ul>
          <li>
            Operationally Necessary. This includes Technologies that allow you access to our Services, applications, and tools that are required to
            identify irregular site behavior, prevent fraudulent activity and improve security or that allow you to make use of our functionality;
          </li>
          <li>
            Performance Related. We may use Technologies to assess the performance of our Services, including as part of our analytic practices to
            help us understand how our visitors use the Services;
          </li>
          <li>
            Functionality Related. We may use Technologies that allow us to offer you enhanced functionality when accessing or using our Services.
            This may include identifying you when you sign into our Services or keeping track of your specified preferences, interests, or past items
            viewed;
          </li>
          <li>
            Advertising or Targeting Related. We may use first party or third-party Technologies to deliver content, including ads relevant to your
            interests, on our Services or on third party sites.
          </li>
        </ul>
        <p>
          If you would prefer not to accept cookies or otherwise wish to disable our use of tracking technologies, please see "Managing Your
          Information," below. Please note that doing so may negatively impact your experience using the Services, as some features may not work
          properly.
        </p>

        <h3>Aggregated and de-identified information</h3>
        <p>
          We may collect information by aggregating and de-identifying other information. The aggregation and de-identification process prevents the
          information from being reassociated or identified with any one customer account, user, or individual. We may use aggregated and
          de-identified information for a wide variety of statistical and analytical purposes.
        </p>

        <h2>How we use the information we collect</h2>
        <p>
          We use the information we collect for a variety of purposes, and how we use it depends on what we collect and what Services you use (or
          features of the Services). These purposes may include:
        </p>
        <ul>
          <li>
            Fulfilling our contract with you and providing the Services to you, such as:
            <ul>
              <li>Responding to requests or inquiries from you</li>
              <li>Providing customer support or technical assistance</li>
              <li>
                Contacting you to provide product updates, information about products you have requested or purchased, marketing our products,
                services and features that you may be interested in, and monitoring the performance of our advertisements and marketing efforts
              </li>
              <li>Creating, managing, or administering your information and account, including identifying you with your account</li>
              <li>Billing you and processing your financial information and other payment methods for products or Services purchased</li>
              <li>Providing access to certain areas, functionalities, and features of our Services</li>
              <li>Communicating with you about your account, activities on our Services and policy changes</li>
              <li>Undertaking activities to verify or maintain the quality of a service</li>
              <li>
                Providing Services on behalf of our customers, such as maintaining or servicing accounts, providing customer service, and verifying
                customer information
              </li>
              <li>Processing applications and transactions</li>
              <li>Allowing you to register for events</li>
              <li>Sharing with third parties for the purposes discussed in "How we share information", below</li>
            </ul>
          </li>
          <li>
            Analyzing and improving our Services pursuant to our legitimate interest, such as:
            <ul>
              <li>Deriving market insights, ways to improve the Services, and other business analysis or research purposes</li>
              <li>Customizing existing and future product offerings and other aspects of the Services to you and other users</li>
              <li>Securing the Services and our systems, and protecting your information and data</li>
              <li>
                Detecting security incidents, protecting against malicious, deceptive, fraudulent or illegal activity, and prosecuting those
                responsible for that activity
              </li>
              <li>Measuring interest and engagement in our Services and short-term, transient use, such as contextual customization of ads</li>
              <li>Undertaking research for technological development and demonstration</li>
              <li>
                Researching and developing products (including marketing research), services, marketing or security procedures to improve their
                performance, resilience, reliability or efficiency
              </li>
              <li>Improving, upgrading or enhancing our Services</li>
              <li>Developing new products and Services</li>
              <li>Ensuring internal quality control</li>
              <li>Verifying your identity and preventing fraud</li>
              <li>Debugging to identify and repair errors that impair existing intended functionality</li>
              <li>Enforcing our terms and policies</li>
              <li>Complying with our legal obligations, protecting your vital interest, or as may be required for the public good</li>
            </ul>
          </li>
          <li>
            Providing you with additional content and Services, such as:
            <ul>
              <li>Customized materials about offers, products, and Services that may be of interest, including new content or Services</li>
              <li>Auditing relating to interactions, transactions and other compliance activities</li>
              <li>Other purposes you consent to, are notified of, or are disclosed when you provide personal information</li>
            </ul>
          </li>
          <li>
            Marketing Our Products and Services. We may use personal information to tailor and provide you with content and advertisements. We may
            provide you with these materials as permitted by applicable law. If you have any questions about our marketing practices or if you would
            like to opt out of the use of your personal information for marketing purposes, you may contact us at any time as set forth below.
          </li>
          <li>
            Consent. We may use personal information for other purposes that are clearly disclosed to you at the time you provide personal
            information, or with your consent.
          </li>
          <li>Any other legitimate business purpose that does not conflict with the statements made in this Privacy Policy.</li>
        </ul>

        <h3>Personal Information</h3>
        <p>
          Specifically, we may use personal information (including those contained within the Customer Information or otherwise collected by us) for
          the above purposes, but only to the extent necessary for the purposes for which you have provided us with the information, to respond to
          inquiries or requests (including requests for customer support or technical assistance), to otherwise perform our obligations or act
          consistently with our <a href="/terms">Terms of Service</a>, to respond to law enforcement or other governmental or legal requests, and to
          otherwise use the information consistent with your instructions to us (both explicit, such as when you contact us directly with a request,
          and implied, such as when you engage us to provide you with the Services). If you wish to change these instructions, either by request, by
          correcting, amending, or deleting information we may have collected about you, or by opting out of certain data collection practices, please
          see "Managing Your Information", below.
        </p>

        <h3>Use of data provided to us</h3>
        <p>
          Notwithstanding anything else in this Privacy Policy to the contrary, we will not use or access the data you provide to us except in the
          following cases: for providing the Services to you; where you explicitly approve access (e.g., during a customer support inquiry); where
          compelled to do so in response to lawful requests by law enforcement or government regulators; where necessary to ensure the stability and
          security of the Services and our systems; and where necessary to protect the rights, privacy, safety, or property of you, us, or others. We
          may also analyze metadata such as total number of records, file size, API volume, access logs, etc.
        </p>

        <h2>How we share information</h2>
        <p>
          Except as provided below, for purposes of the California Consumer Privacy Act, we do not share, disclose, or sell your personal information.
          For a list of the categories of personal information we have disclosed about consumers for a business purpose in the past 12 months, please
          contact us at 
          <a href="mailto:privacy@trant.io">privacy@trant.io</a>.
        </p>
        <p>We disclose information we collect in the following cases:</p>
        <ul>
          <li>You asked us to, or otherwise gave your specific consent.</li>
          <li>With vendors we engage to provide you with aspects of the Services, such as data storage, hosting, and payment processing.</li>
          <li>With third-party service providers who enable certain features or functionalities of the Services that you’ve requested.</li>
          <li>
            With vendors we engage to help us with marketing and email campaigns, to advertise, gain insights, and perform analytics into how the
            Services are used and how they might be improved (for example, we may share Customer Information with various social media platforms to
            engage in custom audience advertising or use third-party data enrichment services to match Customer Information or other personal
            information we collect with publicly available database information in order to communicate more effectively with you).
          </li>
          <li>
            As necessary to comply with applicable law, including governmental requests, law enforcement requests, and otherwise to protect the
            rights, privacy, safety, or property of you, us, or others.
          </li>
          <li>
            As necessary in the event of a proposed or actual reorganization, merger, sale, joint venture, assignment, transfer, financing, or other
            disposition of all or any portion of our business, assets, or stock.
          </li>
          <li>With others for any legitimate business purpose that does not conflict with the statements made in this Privacy Policy.</li>
        </ul>

        <h2>Data Retention</h2>
        <p>
          We retain the information we receive for as long as you use the Services or as necessary to fulfill the purposes for which it was collected,
          provide our Services, resolve disputes, establish legal defenses, enforce our agreements, and comply with applicable laws.
        </p>

        <h2>How we secure your information</h2>
        <p>
          We implement appropriate technical and organizational measures to protect the information we collect and store. Unfortunately, no security
          measures are 100% foolproof, and as such no network or system (including ours) can be guaranteed to be 100% secure against destruction,
          loss, alteration, unauthorized disclosure of, or access to information we collect and store. If you believe your information may not be
          secure for any reason, please contact us immediately at 
          <a href="mailto:privacy@trant.io">privacy@trant.io</a>.
        </p>

        <h2>Managing Your Information</h2>
        <p>
          You have many choices to access information we collect about you and about how we use or disclose that information. This section details
          many of those choices, including how you can exercise rights you may have with respect to your information (including personal information),
          how you can opt out of collection and use of certain types of information for certain purposes (such as marketing), and how you can use your
          browser or third-party tools to disable certain collection methods (such as cookies or tracking technologies).
        </p>
        <p>
          In accordance with applicable law, you may have the right to: (i) request confirmation of whether we are processing your personal
          information; (ii) obtain access to or a copy of your personal information; (iii) receive an electronic copy of personal information that you
          have provided to us, or ask us to send that information to another company (the “right of data portability”); (iv) restrict our uses of your
          personal information; (v) seek correction or amendment of inaccurate, untrue, incomplete, or improperly processed personal information; (vi)
          withdraw your consent; and (vii) request erasure of personal information held about you by us, subject to certain exceptions prescribed by
          law.
        </p>
        <p>
          If you would like to exercise these rights, please contact us at 
          <a href="mailto:privacy@trant.io">privacy@trant.io</a>. You may be able to exercise some of these rights by logging in to your account and
          navigating to your Account page. Please note that we may retain information that is otherwise deleted in de-identified and/or aggregated
          form, in archived or backup copies as required pursuant to records retention obligations, or as otherwise required by law.
        </p>
        <p>
          We will process your requests in accordance with applicable laws. To protect your privacy, we may take steps to verify your identity before
          fulfilling your request. If you are a California resident, you have the right not to receive discriminatory treatment for the exercise of
          your rights conferred by the California Consumer Privacy Act.
        </p>
        <p>
          We may use some of the information we collect for marketing purposes, including to send you promotional communications about new features,
          products, events, or other opportunities. If you wish to stop receiving these communications or to opt out of use of your information for
          these purposes, please follow the opt-out instructions by clicking "Unsubscribe" (or similar opt-out language) in those communications. You
          can also contact us at 
          <a href="mailto:privacy@trant.io">privacy@trant.io</a> to opt out.
        </p>
        <p>
          Depending on where you live, you may have a right to file a complaint with your local supervisory authority. For more information, please
          contact us at <a href="mailto:privacy@trant.io">privacy@trant.io</a>.
        </p>

        <h3>Cookies and Tracking Technologies – How to Opt Out</h3>
        <p>
          If you would prefer not to accept cookies or otherwise wish to disable our use of tracking technologies, most browsers and mobile devices
          allow you to change your settings so as to notify you when you receive cookies or other tracking technologies are being used, and to choose
          whether or not to accept/allow it. Most browsers also allow you to disable or delete existing cookies or to automatically reject future
          cookies. Disabling cookies may limit your ability to use the Services.
        </p>
        <p>
          Certain tracking technologies we use are related to advertising networks, and through those technologies we may share certain information
          such as IP addresses. Please note that the information we share with those advertising networks might be combined with other information
          about you that those networks may have collected from other sources. If you’re in the EU, you can find additional information about your
          choices with respect to advertising networks and online behavioral advertising by <a href="https://www.youronlinechoices.com/">here</a>.
        </p>
        <p>
          Certain tracking technologies we use involve the use of session replay scripts or other scripts that run directly from your browser. If you
          wish to disable these tracking technologies, you can install a third-party browser analytics blocker plug-in.
        </p>
        <p>
          If you have any questions about how we or our third-party service providers use cookies or other tracking technologies that aren’t answered
          in this Privacy Policy, please contact us at 
          <a href="mailto:privacy@trant.io">privacy@trant.io</a>.
        </p>

        <h2>International Transfers (including Transfers Outside of the European Union)</h2>
        <p>
          We process and store information on servers located in the United States, and we may store information on servers and equipment in other
          countries depending on a variety of factors, including the locations of our users and service providers. By using the Services, you agree to
          the transfer of information (including Customer Information. and personal information) to locations that may be outside of your country of
          residence, including the United States, for us to provide the Services. With respect to transfers of information out of the European Union
          (EU), we may process some personal information pursuant to data processing agreements that include the EU Standard Contractual Clauses. To
          learn more about our data processing agreements, please contact us at 
          <a href="mailto:privacy@trant.io">privacy@trant.io</a>.
        </p>

        <h2>Information from Children</h2>
        <p>
          The Services are not directed to children under the age of 18. If you’re under 18, you may use our Services only with the involvement of a
          parent or guardian.
        </p>

        <h2>Changes to Privacy Policy</h2>
        <p>
          Any information that we collect is subject to the Privacy Policy in effect at the time such information is collected. We may, however,
          revise the Privacy Policy from time to time. If a revision is made, we will notify you, for example via email. The current version will
          always be posted to our Privacy Policy page.
        </p>
        <p>
          If you have any questions about this Privacy Policy, please contact us at 
          <a href="mailto:privacy@trant.io">privacy@trant.io</a>.
        </p>
      </section>
    </Layout>
  );
}
