import { pageContainerName } from "../app-constants";

export function freezePage() {
  //document is not defined in SSR
  if (typeof window !== `undefined`) {
    const element = document.getElementById(pageContainerName);

    if (element) {
      element.style.pointerEvents = "none";
    }
  }
}

export function releasePage() {
  //document is not defined in SSR
  if (typeof window !== `undefined`) {
    const element = document.getElementById(pageContainerName);

    if (element) {
      element.style.pointerEvents = "unset";
    }
  }
}
