import DirectionHorizontalInput from "./input/direction-horizontal-input";
import { Scenario, ScenarioPart, ScenariosList } from "../data/scenarios-types";
import ResultsPage from "./results-page";
import { IonCard, IonCardContent, IonButton } from "@ionic/react";
import styled from "styled-components";
import { useState, useEffect } from "react";

const Card = styled(IonCard)`
  --background: var(--ion-color-light);
  --color: var(--ion-color-dark);
`;

const CenteredButton = styled(IonButton)`
  --background: var(--ion-color-medium);
  --color: var(--ion-color-dark);
  display: block;
  width: 85%;
  padding: 0 12px;
  margin: 12px auto;
`;

function strToHtmlSpan(str: string) {
  return <span dangerouslySetInnerHTML={{ __html: str.replace(/\n/g, "<br/>") }} />;
}

function ScenarioPartCard(props: any) {
  const [shouldShowAnswer, setShouldShowAnswer] = useState(false);
  const [shouldDisable, setShouldDisable] = useState(false);

  //reset the state if the input is changed
  useEffect(() => {
    setShouldShowAnswer(false);
    setShouldDisable(false);
  }, [props.scenarioPart]);

  const handleSubmit = (isCorrect: boolean) => {
    props.onSubmitResult(isCorrect);
    setShouldDisable(true);
  };

  let feedbackIcon: any;
  const containerStyle = props.style ? props.style : {};
  if (props.scenarioPart.isUserResponseCorrect !== undefined) {
    if (props.scenarioPart.isUserResponseCorrect) {
      containerStyle.background = "var(--green100)";
      feedbackIcon = "✔";
    } else {
      containerStyle.background = "var(--red100)";
      feedbackIcon = "✗";
    }
  }

  return (
    <Card {...props} style={containerStyle}>
      <IonCardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "right",
            height: props.scenarioPart.label ? "var(--scale700)" : "24px",
          }}
        >
          <span style={{ fontSize: "var(--fontSize400)", }}>
            {props.scenarioPart.label}
          </span>
          {feedbackIcon}
        </div>
        <div style={{ fontWeight: 600, marginBottom: "var(--scale400)" }}>{props.scenarioPart.promptLabel}</div>
        {strToHtmlSpan(props.scenarioPart.prompt)}
      </IonCardContent>
      {!shouldShowAnswer ? (
        <CenteredButton
          onClick={() => {
            setShouldShowAnswer(true);
          }}
        >
          Show Response
        </CenteredButton>
      ) : (
        <IonCardContent style={{ borderTop: "3px solid var(--ion-color-dark)" }}>
          <div>
            <div style={{ fontWeight: 600, marginBottom: "var(--scale400)" }}>Response:</div>
            <p style={{ fontStyle: "italic" }}>{strToHtmlSpan(props.scenarioPart.expectedResponse)}</p>
            <p style={{ margin: "24px 0", whiteSpace: "pre-line" }}>{strToHtmlSpan(props.scenarioPart.explanation)}</p>
            <div style={{ textAlign: "center", marginTop: "24px" }}>Did you answer correctly?</div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IonButton
                style={{ width: "124px", margin: "6px 12px" }}
                disabled={shouldDisable}
                onClick={() => {
                  handleSubmit(false);
                }}
              >
                No
              </IonButton>
              <IonButton
                style={{ width: "124px", margin: "6px 12px" }}
                disabled={shouldDisable}
                onClick={() => {
                  handleSubmit(true);
                }}
              >
                Yes
              </IonButton>
            </div>
          </div>
        </IonCardContent>
      )}
    </Card>
  );
}

function countCompletedScenarioParts(scenarioParts: Array<ScenarioPart>) {
  return scenarioParts.filter((part: ScenarioPart) => part.isUserResponseCorrect != null).length;
}

/**
 * Takes a scenario than consists of several parts. Displays the parts one by one and fills out the whether the user reponded correctly.
 *
 * @param scenario Scenario consisting of several parts, each having a prompt and a correct response
 * @param onFinish Function to be called when all the parts of the scenario are filled with a user response
 * @param style    Style object
 */
function ScenarioCard({ scenario, onFinish, style }: { scenario: Scenario; onFinish: any; style?: object }) {
  const [refreshToggle, setRefreshToggle] = useState(false);

  const numCompletedParts = countCompletedScenarioParts(scenario.parts);

  const cards: any[] = [];
  for (let i = 0; i < Math.min(numCompletedParts + 1, scenario.parts.length); ++i) {
    cards.push(
      <ScenarioPartCard
        key={i}
        scenarioPart={scenario.parts[i]}
        onSubmitResult={(isAnswerCorrect: boolean) => {
          scenario.parts[i].isUserResponseCorrect = isAnswerCorrect;
          setRefreshToggle(!refreshToggle);
          onFinish();
        }}
      />
    );
  }

  const scenarioLabel = scenario.label ? <h2 style={{ paddingLeft: "var(--scale400)" }}>{scenario.label}</h2> : <h2 />;
  return (
    <div style={style}>
      {scenarioLabel}
      {cards}
    </div>
  );
}

function calculatePerformanceMetrics(scenariosList: ScenariosList) {
  let numQuestions = 0;
  let numCorrectAnswers = 0;
  scenariosList.forEach((scenario) => {
    scenario.parts.forEach((part) => {
      ++numQuestions;
      if (part.isUserResponseCorrect) {
        ++numCorrectAnswers;
      }
    });
  });

  return { finalScore: Math.floor((100.0 * numCorrectAnswers) / numQuestions) };
}

/**
 * Searches for the given ID within a scenario list and returns the indexes of the scenario and scenario part if applicable.
 *
 * @param scenariosList
 * @param targetId
 *
 * @returns Undefined if the ID is not found, or an object containing the scenario index, and scenario part index if available (i.e. if a corresponding part was found).
 */
function findById(scenariosList: ScenariosList, targetId: string) {
  for (let scenarioIdx = 0; scenarioIdx < scenariosList.length; ++scenarioIdx) {
    if (scenariosList[scenarioIdx].id === targetId) {
      return { scenarioIdx };
    }

    const parts = scenariosList[scenarioIdx].parts;
    for (let partIdx = 0; partIdx < parts.length; ++partIdx) {
      if (parts[partIdx].id === targetId) {
        return { scenarioIdx, partIdx };
      }
    }
  }

  return undefined;
}

export default function ScenarioCardsPlayer({ scenariosList, startScenarioId }: { scenariosList: ScenariosList; startScenarioId: string }) {
  const [idx, setIdx] = useState(0);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [shouldShowResults, setShouldShowResults] = useState(false);

  const scenario = scenariosList[idx];
  const numCompletedPartsInActiveScenario = countCompletedScenarioParts(scenario.parts);

  useEffect(() => {
    const { scenarioIdx }: any = findById(scenariosList, startScenarioId);
    setIdx(scenarioIdx);
  }, [startScenarioId]);

  return !shouldShowResults ? (
    <div style={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "space-between", overflow: "auto" }}>
      <ScenarioCard
        scenario={scenario}
        onFinish={() => {
          setRefreshToggle(!refreshToggle);
        }}
        style={{ height: "69vh", overflowY: "auto" }}
      />
      <DirectionHorizontalInput
        style={{ overflow: "hidden", margin: "24px auto" }}
        label={
          <p style={{ textAlign: "center", margin: "auto" }}>
            Scenario {idx + 1} / {scenariosList.length}
          </p>
        }
        onPrev={() => {
          if (idx > 0) {
            setIdx(idx - 1);
          }
        }}
        onNext={() => {
          const idxLastScenario = scenariosList.length - 1;
          if (idx === idxLastScenario) {
            setShouldShowResults(true);
          } else {
            setIdx(idx + 1);
          }
        }}
        isDisabledLeft={idx === 0}
        isDisabledRight={numCompletedPartsInActiveScenario < scenario.parts.length}
      />
    </div>
  ) : (
    <ResultsPage metrics={calculatePerformanceMetrics(scenariosList)} onPrev={() => setShouldShowResults(false)} />
  );
}
