import Layout from "./layout";
import LoadingScreen from "./loading-screen";
import CheckoutButton from "../components/checkout-button";
import AppContext from "../app-context";
import { pricing } from "../app-constants";
import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";

const SubscriptionInfo = styled.div`
  text-align: center;
  color: var(--ion-color-dark);
  margin: 24px auto;

  p {
    font-size: 32px;
  }
`;

export default function (props) {
  const { user } = useContext(AppContext);

  if (!user) {
    return <LoadingScreen />;
  }

  const isSubscriptionActive = user.paidScenarios !== undefined;

  return (
    <Layout>
      <SubscriptionInfo>
        {isSubscriptionActive ? (
          `Your subscription ends on ${new Date(user.subscriptionExpireDate).toDateString()}.`
        ) : (
          <>
            <p>Full Collection</p> <p>{pricing.fullCollection}/year</p>
            <p style={{ fontSize: "18px" }}>Limited time offer</p>
            <CheckoutButton userId={user.userId} />
          </>
        )}
      </SubscriptionInfo>
    </Layout>
  );
}
