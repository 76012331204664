import AppContext, { User } from "../app-context";
import { releasePage, freezePage } from "./page-control";
import { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { API } from "@aws-amplify/api";
import React, { useEffect, useContext } from "react";

export default function useAuth() {
  const appContext: any = useContext(AppContext);

  const freezePage1sec = () => {
    freezePage();
    setTimeout(() => {
      releasePage();
    }, 1000);
  };

  useEffect(() => {
    if (!appContext.isAuthReady) {
      Hub.listen("auth", (data) => {
        switch (data.payload.event) {
          case "signIn":
            appContext.setIsAuthenticated(true);
            appContext.setIsAuthReady(true);
            freezePage1sec();
            break;
          case "signOut":
            appContext.setIsAuthenticated(false);
            appContext.setIsAuthReady(true);
            console.log("signed out");
            freezePage1sec();
            break;
        }
      });

      Auth.currentAuthenticatedUser()
        .then(() => {
          return Auth.currentUserCredentials();
        })
        .then(async (cred) => {
          const response = await API.get("trant", `/user`, {});

          appContext.setIsAuthenticated(true);
          appContext.setIsAuthReady(true);

          const user: User = {
            userId: cred.identityId,
            ...JSON.parse(response.body),
          };
          appContext.setUser(user);
        })
        .catch((e) => {
          console.log(e);
          appContext.setIsAuthenticated(false);
          appContext.setIsAuthReady(true);
        });
    }

    return () => {
      //TODO does not remove the listener but might be a sync issue (Jira EQ-40))
      Hub.remove("auth", () => console.log("removed"));
    };
  }, []);

  useEffect(() => {
    if (!appContext.isAuthReady) {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get("error_description")) {
        console.log("Signing in did not succeed due to missing authorization. Please try again with a different login method.");
      } else if (urlParams.get("code")) {
        freezePage1sec();
        appContext.setIsAuthReady(true);
      } else {
      }
    }
  }, []);

  const login = () => {
    appContext.setIsAuthReady(false);
    freezePage1sec();

    Auth.federatedSignIn()
      .then((cred) => {
        console.log(cred);
        // seems to never get reached
        // releasePage()
        // appContext.setIsAuthenticated(true)
      })
      .catch((e) => {
        console.log(e);
        appContext.setIsAuthenticated(false);
        appContext.setIsAuthReady(true);
      });
  };

  const logout = () => {
    appContext.setIsAuthReady(false);
    freezePage1sec();
    Auth.signOut().then(() => {
      appContext.setIsAuthReady(true);
    });
  };

  const status = {
    isAuthReady: appContext.isAuthReady,
    isAuthenticated: appContext.isAuthenticated,
  };
  return [status, login, logout];
}
