import Layout from "./layout";
import { pricing } from "../app-constants";
import styled from "styled-components";

const PriceContainer = styled.div`
  text-align: center;
  color: var(--ion-color-dark);
  margin: 24px auto;

  p {
    font-size: 32px;
  }
`;

export default function (props) {

  return (
    <Layout>
      <PriceContainer>
        <p>Full Collection</p> <p>{pricing.fullCollection}/year</p>
        <p style={{ fontSize: "18px" }}>Limited time offer</p>
        <p style={{ fontSize: "18px" }}>Login to order</p>
      </PriceContainer>
    </Layout>
  );
}
