import React from "react";

export default function LoadingScreen() {
  return (
    <div style={{ height: "100vh" }}>
      <h2
        style={{
          marginTop: "1em",
          textAlign: "center",
        }}
      >
        Loading...
      </h2>
    </div>
  );
}
