import Pricing from "./components/pricing";
import Success from "./components/success";
import Failure from "./components/failure";
import Home from "./components/home";
import User from "./components/user";
import Cards from "./pages/cards";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import AppContext, { AppContextProvider } from "./app-context";
import PrivateRoute from "./components/private-route";

import { Route } from "react-router-dom";
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ellipse, square, triangle } from "ionicons/icons";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import React, { useContext } from "react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

const tagManagerArgs: TagManagerArgs = {
  gtmId: "GTM-TFJTJ9R",
};

TagManager.initialize(tagManagerArgs);

setupIonicReact();

// Note, there seems to be a bug in ionic that prevents refactoring further into separate components:
//  https://forum.ionicframework.com/t/iontabs-must-contain-an-ionrouteroutlet/205161
function App() {
  const { isAuthenticated }: any = useContext(AppContext);

  const userTabData = isAuthenticated ? { tab: "user", href: "/user", label: "User" } : { tab: "pricing", href: "/pricing", label: "Pricing" };

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/cards" component={Cards} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/success" component={Success} />
            <Route exact path="/failure" component={Failure} />
            <PrivateRoute path="/user" component={User} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/" component={Home} />
          </IonRouterOutlet>

          <IonTabBar slot="bottom">
            {/*Seems / does not work correctly and gets replaced with the last clicked tab */}
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={triangle} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton tab="cards" href="/cards">
              <IonIcon icon={ellipse} />
              <IonLabel>Cards</IonLabel>
            </IonTabButton>
            <IonTabButton tab={userTabData.tab} href={userTabData.href}>
              <IonIcon icon={square} />
              <IonLabel>{userTabData.label}</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}

export default function () {
  return (
    <AppContextProvider>
      <App />
    </AppContextProvider>
  );
}
